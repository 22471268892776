import { AppDefinition } from '@eclipse-theiacloud/common';

interface FooterProps {
  appDefinition: string;
  appName: string;
  additionalApps: AppDefinition[];
  setSelectedAppName: (value: React.SetStateAction<string>) => void;
  setSelectedAppDefinition: (value: React.SetStateAction<string>) => void;
}

export const Footer = ({
  appDefinition,
  appName,
  additionalApps,
  setSelectedAppName,
  setSelectedAppDefinition
}: FooterProps): JSX.Element => (
  <div className='App__footer'>
    <div className='footer__section__left'>
      {additionalApps.length > 0 && (
        <p>
          <label htmlFor='selectapp'>Select app to launch </label>
          <select
            name='apps'
            id='selectapp'
            onChange={event => {
              setSelectedAppName(event.target.options[event.target.selectedIndex].text);
              setSelectedAppDefinition(event.target.value);
            }}
          >
            <option value={appDefinition}>{appName}</option>
            {additionalApps.map((app: any, index: number) => (
              <option key={index + 1} value={app.appId}>
                {app.appName}
              </option>
            ))}
          </select>
        </p>
      )}
    </div>
    <div className='footer__divider' />
    <div className='footer__section__middle'>
      <p>
        Powered by{' '}
        <a href='http://theia-cloud.io' target='_blank' rel='noreferrer'>
          Theia Cloud
        </a>
      </p>
      <p>
        Having problems? Please{' '}
        <a target='_blank' href='https://github.com/eclipsesource/theia-cloud/issues' rel='noreferrer'>
          report an issue
        </a>
        .
      </p>
    </div>
    <div className='footer__divider' />
    <div className='footer__section__right'>
      <p>
        <a target='_blank' href='https://eclipsesource.com/imprint/' rel='noreferrer'>
          Imprint
        </a>
      </p>
      <p>
        <a target='_blank' href='https://www.iubenda.com/privacy-policy/47849639/cookie-policy' rel='noreferrer'>
          Cookie Policy
        </a>
      </p>
      <p>
        <a target='_blank' href='https://www.iubenda.com/privacy-policy/47849639' rel='noreferrer'>
          Privacy Policy
        </a>
      </p>
    </div>
  </div>
);
